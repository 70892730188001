document.addEventListener("DOMContentLoaded", function (event) {
  setTimeout(addScript, 4000);
});

function addScript() {
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.onload = function () {
    console.log("Added Script 1");
  };
  script.src = "https://vawidget.dhl.com/latest/embed.js";
  document.getElementsByTagName("head")[0].appendChild(script);

  if (window.dhlVAWidget) {
    if (dhlStatic.currentLanguage) {
      if (dhlStatic.currentLanguage == "pl") {
        window.dhlVAWidget.init({
          id: "express-cs-eu/pl/41732b7c-8009-4d3e-9f2f-1ab9f5ad4291",
          languageCode: "pl",
        });
      } else if (dhlStatic.currentLanguage == "en") {
        window.dhlVAWidget.init({
          id: "express-cs-eu/pl/41732b7c-8009-4d3e-9f2f-1ab9f5ad4291",
          languageCode: "en",
        });
      }
    }

    const footerAssistanceBtn = document.querySelector(".c-footer__assistance");
    if (footerAssistanceBtn) {
      footerAssistanceBtn.addEventListener("click", (e) => {
        e.preventDefault();
        window.dhlVAWidget.open();
      });
    }
  }
}
